<template>
  <div class="m-page page">
    <!-- 标题 -->
    <div class="title">{{ detail.newsTitle }}</div>
    <!-- 来源 & 时间 -->
    <div class="credit">
      <div class="source">来源: {{ detail.newsSource }}</div>
      <div class="date">日期: {{ detail.publishedDateTxt }}</div>
    </div>
    <div class="divider" />
    <!-- 内容 -->
    <div v-html="detail.newsContent" class="content" @click.capture="handlePreviewImage" />
    <!-- 底部数据及按钮 -->
    <div class="buttons">
      <div class="left">
        <div class="views">
          <i class="el-icon-view" />
          <span>{{ detail.viewCountTxt }}</span>
        </div>
        <!-- <div class="favorites">
          <i class="el-icon-star-off" />
          <span>{{ detail.collectCountTxt }}</span>
        </div> -->
      </div>
      <div class="right">
        <div class="share" @click="handleShare">
          <i class="el-icon-share" />
          <span>分享</span>
        </div>
      </div>
    </div>

    <!-- 分享弹出层 -->
    <el-drawer
      :visible.sync="shareDrawerVisible"
      title="分享弹出层"
      :with-header="false"
      direction="btt"
      size="20%"
    >
      <div class="share-drawer">
        <div class="share-btn" @click="handleShareLayer(true)">
          <div class="btn-icon">
            <van-icon name="wechat" size="28" color="#24db5a" />
          </div>
          <span class="btn-text">微信</span>
        </div>
        <div class="share-btn" @click="handleShareLink">
          <div class="btn-icon">
            <i class="el-icon-link" style="font-size: 28px" />
          </div>
          <span class="btn-text">复制链接</span>
        </div>
      </div>
    </el-drawer>

    <!-- 微信小程序分享遮罩层 -->
    <share-layer :visible="shareLayerVisible" @close="handleShareLayer(false)" />
  </div>
</template>

<script>
import { NewsDetails } from '../../../api'
import { ImagePreview } from 'vant'
import { Icon as VanIcon, Toast } from 'vant'
import ShareLayer from '../../../components/ShareLayer'
// import wx from 'weixin-js-sdk'

export default {
  name: 'MobileNewsDetail',

  components: { VanIcon, ShareLayer },

  data() {
    return {
      // 当前新闻ID
      id: 0,
      // uid,
      // 新闻详情数据
      detail: {},
      // 分享抽屉的可见性
      shareDrawerVisible: false,
      // 分享遮罩层的可见性
      shareLayerVisible: false,
    }
  },

  created() {
    const { id } = this.$route.query
    if(id) {
      this.id = id
      this.getDetailData()
    }
  },

  methods: {
    /* 发送请求, 获取新闻详情 */
    getDetailData() {
      const { id } = this
        NewsDetails({ id }).then(res => {
          const { data: detail } = res
          const { newsTitle, publishedTime, viewCount, collectCount } = detail
        this.detail = {
          ...detail,
          publishedDateTxt: publishedTime && publishedTime.includes(' ') ? publishedTime.split(' ')[0] : '-',
          // 阅读量
          // viewsTxt: `${~~(Math.random() * 10000)}阅读`,
					viewCountTxt: `${viewCount}阅读`,
					// 收藏量
          // favoritesTxt: `${~~(Math.random() * 10000)}收藏`,
					collectCountTxt: `${collectCount}收藏`
        }
        document.title = newsTitle
      }).catch(error => {
        console.log('getDetailData', error)
      })
    },

    /* 自定义小程序分享内容 */
    updateWechatShareData() {
      // weixin-js-sdk config配置
      wx.config({
        debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: '', // 必填，公众号的唯一标识
        timestamp: '', // 必填，生成签名的时间戳
        nonceStr: '', // 必填，生成签名的随机串
        signature: '',// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的 JS 接口列表
      })
      const { id, newsTitle = '', newsCover = '' } = this.detail
      wx.ready(() => {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({ 
          title: newsTitle, // 分享标题
          desc: '2022中国(中山)花木产业大会云展会', // 分享描述
          link: `/pages/webView/webView?src=https://sc.miaocang.cc/#/mNewsDetail&query={"id": "${id}"}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: newsCover || '', // 分享图片
          success: () => {
            console.log('updateAppMessageShareData success')
          }
        })
        wx.updateTimelineShareData({ 
          title: newsTitle, // 分享标题
          desc: '2022中国(中山)花木产业大会云展会', // 分享描述
          link: `https://sc.miaocang.cc/#/mNewsDetail?id=${id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: newsCover || '', // 分享图片
          success: () => {
            console.log('updateTimelineShareData success')
          }
        })
      })
    },

    /* 点击查看大图的点击事件处理 */
    handlePreviewImage(e) {
      const { nodeName, currentSrc } = e.target
      nodeName === 'IMG' && ImagePreview({
        images: [currentSrc],
        showIndex: false,
        loop: false
      })
    },
    
    /* 分享按钮的点击事件处理 */
    handleShare() {
      this.shareDrawerVisible = true
    },

    /* 微信分享的点击事件处理 */
    handleShareLayer(visible) {
      this.shareLayerVisible = visible
    },

    /* 复制链接的点击事件处理 */
    handleShareLink() {
      const { id } = this
      if(id) {
        navigator.clipboard.writeText(window.location.href)
        this.shareDrawerVisible = false
        Toast.success('链接已复制到剪贴板')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  min-height: 100vh;
  padding: 0 24px;
  background: linear-gradient(180deg, #e2e9f3 0%, #c6d4e8 100%);
  color: #333;
  .title {
    margin: 0 0 24px;
    padding-top: 24px;
    text-align: center;
    font-size: 30px;
    line-height: 42px;
    font-weight: 600;
  }

  .credit {
    display: flex;
    justify-content: center;
    line-height: 24px;
    font-size: 20px;
    color: #666;
    .source {
      margin-right: 24px;
    }
  }

  .content {
    padding-bottom: 80px;
    font-size: 22px;
    overflow: hidden;
  }

  .buttons {
    position: absolute;
    bottom: 4vh;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 24px * 2);
    color: #666;
    font-size: 20px;
    line-height: 28px;

    i {
      margin-right: 2px;
    }

    .left {
      display: flex;
      .views {
        margin-right: 12px;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background-color: #979797;
  }

  .share-drawer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    
    .share-btn {
      display: flex;
      align-items: center;
      .btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: #ebebeb;
      }

      .btn-text {
        margin-left: 12px;
        font-size: 24px;
      }
    }
  }
}
</style>