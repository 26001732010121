<template>
  <div v-if="visible" class="share-layer" @click.capture="handleClose">
    <div class="tip">点击右上角 ••• 按钮分享给好友</div>
    <svg
      t="1665646741094"
      class="arrow"
      width="64"
      height="64"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="14697"
    >
      <path
        d="M312.533333 320l109.226667-109.226667-60.373333-60.373333L149.333333 362.666667l211.2 211.2 61.226667-61.866667-106.666667-106.666667A512 512 0 0 1 789.333333 874.666667h85.333334a597.333333 597.333333 0 0 0-562.133334-554.666667z"
        p-id="14698"
        fill="#fff"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ShareLayer",

  props: {
    visible: {
      type: Boolean,
      value: false,
    },
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.share-layer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.6);

  .tip {
    position: absolute;
    top: 66px;
    right: 100px;
    // right: 150px;
    font-size: 24px;
    color: #fff;
  }
  .arrow {
    position: absolute;
    top: 10px;
    // right: 66px;
    right: 10px;
    transform: rotate(90deg);
  }
}
</style>